import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import FeaturesSection from '../components/common/contentSections/FeaturesSection';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import VideoSection from '../components/common/contentSections/VideoSection';
import { Feature } from '../utils/entities';

const FeaturesList: Feature[] = [
    {
        title: `No manual form filling`,
        imageUrl: `/images/easy-form-filling@3x.png`,
        desc: `A multi-factor authentication solution for employees, partners, and customers accessing apps, systems, and devices`,
    },
    {
        title: `Saves time`,
        imageUrl: `/images/easy-time@3x.png`,
        desc: `Admins can implement multi-factor authentication easily, without impacting end user productivity`,
    },
    {
        title: `Verified access`,
        imageUrl: `/images/easy-verified@3x.png`,
        desc: `Create intelligent policies based on login context that limit identity challenges to risky login attempts`,
    },
    {
        title: `Control entitlement`,
        imageUrl: `/images/easy-control@3x.png`,
        desc: `A multi-factor authentication solution for employees, partners, and customers accessing apps, systems, and devices`,
    },
    {
        title: `Social  Authentication`,
        imageUrl: `/images/easy-social@3x.png`,
        desc: `Admins can implement multi-factor authentication easily, without impacting end user productivity`,
    },
    {
        title: `Device Flexibility`,
        imageUrl: `/images/easy-device@3x.png`,
        desc: `Create intelligent policies based on login context that limit identity challenges to risky login attempts`,
    },
];

const EasyApplication = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Easy Application"
                desc="Apply easily to any company or any other form of application including travel visa, international passport and other government/national applications with your already aggregated verified information. By using your unique and  secured login credentials, you do not have to worry about having to fill any more forms ever again."
                buttonText="Get Started"
                imageUrl="/images/easy-application-hero.svg"
            />
            <HeadingSection heading="Features" subheading="Easy Application" />
            <FeaturesSection featureList={FeaturesList} />
            <VideoSection />
            <BannerSection />
        </Container>
    </Layout>
);

export default EasyApplication;
