import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Feature } from '../../../utils/entities';
import { Image } from '../../../utils/image';

interface FeaturesSectionProps {
    featureList: Feature[];
}

const FeaturesSection = ({
    featureList,
}: FeaturesSectionProps): ReactElement => (
    <Row noGutters>
        {featureList.map(({ title, desc, imageUrl }) => (
            <Col key={title} md="12" lg="4" style={{ textAlign: `center` }}>
                <div style={{ textAlign: `center` }}>
                    <Image
                        style={{ height: `4rem`, width: `4rem` }}
                        src={imageUrl}
                    />
                </div>
                <div
                    style={{
                        fontSize: `1.2rem`,
                        fontWeight: `bold`,
                        paddingTop: `1rem`,
                    }}
                >
                    {title}
                </div>
                <p
                    style={{
                        padding: `1rem`,
                        paddingLeft: `5rem`,
                        paddingRight: `5rem`,
                        color: `#535b6c`,
                    }}
                >
                    {desc}
                </p>
            </Col>
        ))}
    </Row>
);

export default FeaturesSection;
